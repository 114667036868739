<template lang="pug">
BasePane
  Accordion(fix)
    OmButton(primary block @click="showSettings") {{ $t('editHTML') }}
    <!--<editor-fold desc="width">-->
    Dropdown#width.mt-3(
      layout="col"
      :label="$t('fit')"
      :items="widthOptions"
      v-model="widthType"
      editMobile
    )
    RangeInput(
      v-if="widthType === 'manual'"
      :label="$t('width')"
      v-model.number="width"
      :min="0"
      :max="600"
      :step="1"
    )
    <!--</editor-fold>-->
    RangeInput(
      :label="$t('height')"
      v-model.number="height"
      :min="0"
      :max="600"
      :step="1"
      editMobile
    )
    Align(v-if="widthType === 'manual'" horizontal justifyOptions editMobile v-model="textJustify")
  hr.mt-3.mb-3
  template(#advancedContent)
    CornerRadius(:editMobile="false" radiusProperty="desktop.border.radius")
    Border(typeOfComponent="customHTML" coloringProperty="$device.border.color")
    Shadow(
      typeOfComponent="customHTML"
      editMobile
      v-model="shadow"
      coloringProperty="$device.shadow.color"
    )
    Padding
    Margin
    Hide.sidebar-spacing-top(
      :hideOnDesktop.sync="hideElementOnDesktop"
      :hideOnMobile.sync="hideElementOnMobile"
    )
</template>

<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import Padding from '@/components/Editor/Controls/InputGroup/Padding.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      Dropdown,
      RangeInput,
      Align,
      Hide,
      Padding,
      Margin,
      Shadow,
      Border,
      CornerRadius,
    },

    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
    }),
    computed: {
      ...mapState(['selectedElement']),
      widthOptions() {
        return [
          { value: '100w', text: this.$t('w100') },
          { value: 'manual', text: this.$t('manual') },
        ];
      },
      height: {
        get() {
          return Number(this.getValueOf('$device.height', 0));
        },
        set(v) {
          this.setValueOf('$device.height', v);
        },
      },
      width: {
        get() {
          return Number(this.getValueOf('$device.smartSize.width', 100));
        },
        set(v) {
          this.setValueOf('$device.smartSize.width', v);
        },
      },
      widthType: {
        get() {
          return this.getValueOf('$device.smartSize.type');
        },
        set(value) {
          this.setValueOf('$device.smartSize.type', value);
        },
      },
      textJustify: {
        get() {
          return this.getValueOf('$device.textJustify');
        },
        set(v) {
          this.setValueOf('$device.textJustify', v);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      shadow: {
        get() {
          return this.getValueOf('$device.shadow.type');
        },
        set(v) {
          this.setValueOf('$device.shadow.type', v);
        },
      },
    },

    methods: {
      showSettings() {
        window.parent.om.bus.$emit('changeFormManagerVisibility', { show: 'customHTMLSettings' });
      },
    },
  };
</script>
